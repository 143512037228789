<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Orders' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field label="ID">
                <b-input
                  v-model="code"
                  disabled
                  placeholder="AUTOGENERATED"
                ></b-input>
              </b-field>
              <b-field
                label="Client"
                :type="$store.getters.hasErrors('user_id').type"
                :message="$store.getters.hasErrors('user_id').message"
              >
                <b-autocomplete
                  v-model="client_name"
                  :data="users"
                  placeholder="e.g. Name or Email"
                  field="name"
                  @select="option => (selectedUser = option)"
                  :clearable="$route.params.id ? false : true"
                  :disabled="$route.params.id ? true : false"
                >
                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-content">
                        {{ props.option.name }}
                        <br />
                        <small>
                          {{ props.option.role }} [{{ props.option.email }}]
                        </small>
                      </div>
                    </div>
                  </template>
                  <template slot="footer">
                    <span v-show="page > totalPages" class="has-text-grey">
                      No more clients to show.
                    </span>
                  </template>
                </b-autocomplete>
              </b-field>
              <b-field label="Products">
                <div class="p-4 border rounded">
                  <div
                    class="mb-4 flex items-center"
                    v-for="(product, index) in selectedProducts"
                    :key="index"
                  >
                    <div class="">
                      <img
                        width="50"
                        :src="
                          product.image
                            ? product.image.thumb
                            : $default_image_thumb
                        "
                        loading="lazy"
                      />
                    </div>
                    <div class="flex-grow ml-4">
                      {{ product.name }}
                      <br />
                      <small>
                        {{ product.code }} |
                        {{
                          product.pivot ? product.pivot.price : product.price
                        }}
                      </small>
                    </div>
                    <div class="">
                      <b-numberinput
                        v-model="product.pivot.quantity"
                        size="is-small"
                        type=""
                        min="1"
                        :editable="true"
                        controls-position=""
                      ></b-numberinput>
                    </div>
                    <div class="ml-4">
                      <b-button
                        size="is-small"
                        type=""
                        @click="onProductRemove(index)"
                        >Remove</b-button
                      >
                    </div>
                  </div>
                  <b-field label="Total Amount">
                    USD: {{ totalAmount }}
                  </b-field>
                  <div>
                    <b-autocomplete
                      open-on-focus
                      :disabled="!user_id"
                      :data="products"
                      placeholder="Add a product e.g. 2505-0001 or Santa Claus"
                      :loading="isFetching"
                      :check-infinite-scroll="true"
                      @typing="getProducts"
                      @select="option => onProductSelect(option)"
                      @infinite-scroll="getMoreProducts"
                      clearable
                    >
                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-left">
                            <img
                              width="50"
                              :src="
                                props.option.image
                                  ? props.option.image.thumb
                                  : $default_image_thumb
                              "
                              loading="lazy"
                            />
                          </div>
                          <div class="media-content">
                            {{ props.option.name }}
                            <br />
                            <small>
                              {{ props.option.code }} | {{ props.option.price }}
                            </small>
                          </div>
                        </div>
                      </template>
                      <template slot="footer">
                        <span v-show="page > totalPages" class="has-text-grey">
                          No more products to show.
                        </span>
                      </template>
                    </b-autocomplete>
                  </div>
                </div>
              </b-field>
              <b-field
                class="-mt-2"
                :type="$store.getters.hasErrors('products').type"
                :message="$store.getters.hasErrors('products').message"
              ></b-field>
            </div>
          </div>
          <div class="px-2 w-4/12">
            <FeaturedGallery
              title="Documents"
              :image_ids="image_ids"
              type="*"
            />

            <div class="p-4 border">
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import FeaturedGallery from "@/components/admin/FeaturedGallery";
import store from "@/store";
import { Events } from "@/events.js";
export default {
  components: {
    Sidebar,
    FeaturedGallery
  },
  data: () => ({
    code: "",
    bracket: "",
    user_id: null,
    joborder_id: null,
    image_ids: [],
    users: [],
    selectedUser: null,
    products: [],
    selectedProducts: [],
    isFetching: false,
    client_name: "",
    saved_client_name: "",
    product_name: "",
    page: 1,
    totalPages: 1
  }),
  watch: {
    selectedUser() {
      this.user_id = this.selectedUser ? this.selectedUser.id : null;
      this.bracket = this.selectedUser ? this.selectedUser.role : null;
    }
  },
  computed: {
    totalAmount() {
      let res = 0;
      _.forEach(this.selectedProducts, product => {
        res +=
          parseInt(product.pivot.quantity) * parseFloat(product.pivot.price);
      });
      return res.toFixed(2);
    }
  },
  methods: {
    onSave() {
      store.dispatch("saveOrdersForm", {
        id: this.$route.params.id || null,
        user_id: this.user_id,
        products: _.map(this.selectedProducts, product => {
          return {
            id: product.id,
            quantity: product.pivot.quantity,
            price: product.pivot.price
          };
        }),
        attachments: this.image_ids
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this order?`,
        ids: [this.$route.params.id],
        path: "orders",
        push: "Orders"
      });
    },
    getUsers: _.debounce(function(client_name) {
      // String update
      if (this.saved_client_name !== client_name) {
        this.saved_client_name = client_name;
        this.users = [];
        this.page = 1;
        this.totalPages = 1;
      }
      // String cleared
      if (!client_name.length) {
        this.users = [];
        this.page = 1;
        this.totalPages = 1;
        return;
      }
      // Reached last page
      if (this.page > this.totalPages) {
        return;
      }
      this.isFetching = true;
      axios({
        url: "admin/users",
        method: "get",
        params: {
          page: this.page,
          search: this.saved_client_name,
          clients: true
        },
        headers: store.getters.authorizationHeader
      })
        .then(({ data }) => {
          data.data.forEach(item => this.users.push(item));
          this.page++;
          this.totalPages = data.last_page;
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getMoreUsers: _.debounce(function() {
      this.getUsers(this.saved_client_name);
    }, 250),
    getProducts: _.debounce(function(product_name) {
      // String update
      if (this.product_name !== product_name) {
        this.product_name = product_name;
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
      }
      // String cleared
      if (!product_name.length) {
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
        return;
      }
      // Reached last page
      if (this.page > this.totalPages) {
        return;
      }
      this.isFetching = true;
      axios({
        url: "admin/products",
        method: "get",
        params: {
          page: this.page,
          search: this.product_name,
          bracket: this.bracket,
          variants: true
        },
        headers: store.getters.authorizationHeader
      })
        .then(({ data }) => {
          data.data.forEach(item => this.products.push(item));

          this.page++;
          this.totalPages = data.last_page;
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getMoreProducts: _.debounce(function() {
      this.getProducts(this.product_name);
    }, 250),
    onProductSelect(option) {
      this.product_name = "";
      this.products = [];
      this.page = 1;
      if (option) {
        let i = _.findIndex(this.selectedProducts, ["id", option.id]);
        if (i > -1) {
          this.selectedProducts[i].pivot.quantity++;
        } else {
          option.pivot = {
            quantity: 1,
            price: option.price
          };
          this.selectedProducts.push(option);
          this.$set(this.selectedProducts, "quantity", option.pivot);
        }
      }
    },
    onProductRemove(index) {
      this.selectedProducts.splice(index, 1);
    },
    setProductImageId(ids) {
      this.image_id = ids.length ? ids[0] : null;
    },
    setGalleryImageIds(ids) {
      this.image_ids = ids;
    }
  },
  beforeMount() {
    Events.$on("image-selected", this.setProductImageId);
    Events.$on("gallery-selected", this.setGalleryImageIds);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch, { id: to.params.id }).then(res => {
        next(vm => {
          if (res.data.order) {
            vm.user_id = res.data.order.user.id;
            vm.bracket = res.data.order.user.role;
            vm.client_name = res.data.order.user.name;
            vm.code = res.data.order.code;
            _.forEach(res.data.order.files, file => {
              vm.image_ids.push(file.id);
            });
            _.forEach(res.data.order.products, product => {
              if (res.data.order.user.role == "client-partner")
                product.pivot.price = product.price_partner;
              if (res.data.order.user.role == "client-wholesale")
                product.pivot.price = product.price_wholesale;
              product.pivot.quantity = parseFloat(product.pivot.quantity);
              vm.selectedProducts.push(product);
            });
          }
          vm.users = res.data.clients;
        });
      });
    }
  }
};
</script>
